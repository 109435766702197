// Order Pakan

// Color
$color-primary: #2cb96a;
$color-dark-grey: #ababab;
$color-light-grey: #ececec;
$color-shadow-grey: #353a5d1a;
$color-orange: #ff691b;
$color-light-orange: #ffa836;
$color-dark-orange: #de5252;
$color-green: #4d7f62;
$color-red: #ff1b1b;
$color-light-red: #ff7f7f;
$color-dark-red: #8b0000;

// Font logo
$font-logo-title: 15px; //circular std
$font-logo-subtitle: 8px;

// Font input
$font-input-value-placeholder: 14px; //segoe ui
$font-input-label: 12px;

// Font content
$font-content-title: 18px; //segoe ui
$font-content-subtitle: 14px;

// Font table
$font-table: 12px;

// Font dashboard
$font-dashboard-title: 14px;
$font-dashboard-counter: 32px;

// Material
$font-material-icon-size: 20px;

// // Button
// $btn-normal-width: 150px;
// $btn-normal-height: 50px;

// Modal
$modal-small: 375px;
$modal-medium: 500px;
$modal-large: 800px;
